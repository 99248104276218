<template>
	<div>
		<router-view v-if="!noFormation"></router-view>
		<NoFormation v-else />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import NoFormation from './NoFormation';
import { isPastGivenTime } from '@/utils/time.js';
import { checkShouldRedirectToTestPage } from '../utils/login';

export default {
	name: 'Dashboard',
	components: { NoFormation },
	...mapGetters('profile', { allFormationsAndConfigs: 'allFormationsAndConfigs' }),
	data() {
		return { noFormation: false };
	},
	async created() {
		try {
			/**
			 * Some page like Parametres, Rules dont need to redirect logic below. Use disableDashboardRedirect query
			 */
			const { disableDashboardRedirect } = this.$route.query;
			if (disableDashboardRedirect === true || disableDashboardRedirect === 'true') return;

			const formations = await this.$store.dispatch('formation/getAllFormationsOfUser'); // Get all formation of this user
			if (formations.length > 0) {
				await this.$store.dispatch('profile/fetchAllFormationsAndTopic');
			}

			if (formations.length === 1 && formations[0].for_session === true) {
				this.directionForRedactionFormation(formations[0]._id);
				return;
			}

			if (formations.length === 1 && formations[0].for_session != true) {
				await this.directionForOrthographFormation(formations[0]);
				return;
			}

			/** Special scenarios:
			 *  User some how access to path  /dashboard/topic/:topicSlug/:formationId which should not happened if they follow the flow
			 * 	When user go to this path: redirect to redaction/cyberiq/... formation that begin date is in the past.
			 * */
			if (this.$route.path.includes('/dashboard/topic')) {
				const redactionFormations = formations.filter((f) => f.for_session === true);
				const startedRedactionFormations = this.filterFormationBegan(redactionFormations); // Find formations began
				if (startedRedactionFormations[0]) {
					return this.directionForRedactionFormation(startedRedactionFormations[0]._id);
				}
			}

			const startedFormations = this.filterFormationBegan(formations); // Find formations began
			// If only 1 formation began . Check what is it. Go to that formation
			if (startedFormations.length === 1) {
				if (startedFormations[0].for_session === true) {
					this.directionForRedactionFormation(startedFormations[0]._id);
					return;
				} else {
					await this.directionForOrthographFormation(startedFormations[0]);
					return;
				}
			}

			const startedOrthographFormation = startedFormations.find((f) => f.for_session !== true); // If more than 1 formation began. Find orthograph formation.
			// If found orthograph. Go to orthograph
			if (startedOrthographFormation) {
				await this.directionForOrthographFormation(startedOrthographFormation);
				return;
			}
			// If not found orthogprah. Go to first formation.
			if (startedFormations[0].for_session === true) {
				this.directionForRedactionFormation(formations[0]._id);
				return;
			} else {
				await this.directionForOrthographFormation(formations[0]);
			}
		} catch {
			// When there is no formation, check if user have test to redirect to test
			const canContinue = await checkShouldRedirectToTestPage(() => this.$router.push({ name: 'TestHomePage' }));
			if (canContinue === false) return;

			this.noFormation = true;
		}
	},
	methods: {
		async directionForOrthographFormation(formation) {
			// Check if Orthograph formation need to do onboarding
			if (formation.onboarding_done !== true || formation.score_test == null) {
				return this.$router.push({ name: 'Onboarding', params: { idUser: formation.user_id } });
			}

			this.$store.dispatch('appState/selectFormationId', { formationId: formation._id });
			await this.$store.dispatch('profile/getProfileAndExerciseFormationInfos'); // May change the api to just return formation for_session false because it get formation by user id so it may include formation redaction
			if (this.$route.path === '/dashboard') {
				this.$router.push('/dashboard/profile');
			} else if (this.$route.path === '/dashboard/profile/') {
				this.$router.push('/dashboard');
				location.reload();
			} else if (this.$route.path.includes('/dashboard/topic')) {
				// When user dont have redaction but some how go to path /dashboard/:topicSlug redirect to orthograph
				this.$router.push('/dashboard');
				location.reload();
			}
		},
		async directionForRedactionFormation(formationId) {
			this.$store.dispatch('appState/selectFormationId', { formationId: formationId });
			let _allformationAndTopic = this.allFormationsAndConfigs;
			if (_allformationAndTopic == null) {
				_allformationAndTopic = await this.$store.dispatch('profile/fetchAllFormationsAndTopic');
			}
			const formationAndConfig = _allformationAndTopic.find((f) => f._id === formationId);
			this.$router.push({
				name: 'Topic Dashboard Index',
				params: { topicSlug: formationAndConfig.config.topic_id.slug, formationId: formationId },
			});
		},
		filterFormationBegan(formations) {
			return formations.filter((f) => isPastGivenTime(f.begin_date));
		},
	},
};
</script>
